export default [
  {
    label: "🇦🇺 AUSTRALIA",
    options: [{ label: "Logan City Council Libraries", value: "logan" }],
  },
  {
    label: "🇨🇦 CANADA",
    options: [
      { label: "Aurora Public Library", value: "aurora" },
      { label: "Barrie Public Library", value: "barrie" },
      { label: "Brantford Public Library", value: "brantford" },
      { label: "Burlington Public Library", value: "burlington" },
      { label: "Burnaby Public Library", value: "burnaby" },
      { label: "Calgary Public Library", value: "calgary" },
      { label: "Chinook Arch Regional Library System", value: "chinookarch" },
      { label: "Edmonton Public Library", value: "epl" },
      { label: "Fort Saskatchewan Public Library", value: "fspl" },
      { label: "Fort St John Public Library", value: "fsjpl" },
      { label: "Fraser Valley Regional Library", value: "fvrl" },
      { label: "Greater Sudbury Public Library", value: "gspl" },
      { label: "Halifax Public Libraries", value: "halifax" },
      { label: "Hamilton Public Library", value: "hpl" },
      { label: "Innisfil ideaLAB & Library", value: "innisfil" },
      { label: "Markham Public Library", value: "markham" },
      { label: "Nelson Public Library", value: "nelson" },
      { label: "New Westminster Public Library", value: "newwestminster" },
      { label: "North Perth Public Library", value: "nppl" },
      { label: "Oakville Public Library", value: "opl" },
      { label: "Okanagan Regional Library", value: "orl" },
      { label: "Ottawa Public Library", value: "ottawa" },
      { label: "Oshawa Public Libraries", value: "oshlib" },
      { label: "Pemberton & District Public Library", value: "pemberton" },
      { label: "Perth East Public Library", value: "pepl" },
      { label: "Pickering Public Library", value: "pickering" },
      { label: "Port Moody Public Library", value: "portmoody" },
      { label: "Red Deer Public Library", value: "rdpl" },
      { label: "Richmond Public Library", value: "yourlibrary" },
      { label: "Shortgrass Library System", value: "shortgrass" },
      { label: "Squamish Public Library", value: "squamish" },
      { label: "St. Albert Public Library", value: "stalbert" },
      { label: "St. Marys Public Library", value: "stmarys" },
      { label: "St. Thomas Public Library", value: "stthomas" },
      { label: "SDG Library", value: "sdglibrary" },
      { label: "Stratford Public Library", value: "spl" },
      { label: "Stratchona County Library", value: "scl" },
      { label: "Surrey Libraries", value: "surrey" },
      { label: "Trail & District Public Library", value: "traillibrary" },
      { label: "Vancouver Island Regional Library", value: "virl" },
      { label: "Vancouver Public Library", value: "vpl" },
      { label: "Vaughan Public Libraries", value: "vaughanpl" },
      { label: "Wellington County Library", value: "wellington" },
      { label: "West Perth Public Library", value: "wppl" },
      { label: "West Vancouver Memorial Library", value: "westvanlibrary" },
      { label: "Whistler Public Library", value: "whistler" },
      { label: "Whitby Public Library", value: "whitby" },
      { label: "Windsor Public Library", value: "windsor" },
    ],
  },
  {
    label: "🇳🇿 NEW ZEALAND",
    options: [{ label: "Christchurch City Libraries", value: "christchurch" }],
  },
  {
    label: "🇺🇸 UNITED STATES",
    options: [
      { label: "Alameda County Library", value: "aclibrary" },
      { label: "Arapahoe Libraries", value: "arapahoelibraries" },
      { label: "Austin Public Library", value: "austin" },
      { label: "Bellingham Public Library", value: "bellingham" },
      { label: "Boca Raton Public Library", value: "bocalibrary" },
      { label: "Boston Public Library", value: "bpl" },
      { label: "Carmel Clay Public Library", value: "carmel" },
      { label: "Central Arkansas Library System", value: "cals" },
      { label: "Central Rappahannock Regional Library", value: "librarypoint" },
      { label: "Chapel Hill Public Library", value: "chpl" },
      { label: "Charlotte Mecklenburg Library", value: "cmlibrary" },
      { label: "Chicago Public Library", value: "chipublib" },
      { label: "Cincinnati Public Library", value: "cincinnatilibrary" },
      { label: "Clark County Public Library", value: "ccplohio" },
      { label: "Colombus Metropolitan Library", value: "cml" },
      { label: "Contra Costa County Library", value: "ccclib" },
      { label: "Coronado Public Library", value: "coronado" },
      { label: "Daniel Boone Regional Library", value: "dbrl" },
      { label: "Delaware County District Library", value: "delawarelibrary" },
      { label: "Denton Public Library", value: "denton" },
      { label: "Deschutes Public Library", value: "dpl" },
      { label: "Douglas County Libraries", value: "dcl" },
      { label: "Durham County Library", value: "durhamcounty" },
      { label: "East Lansing Public Library", value: "elpl" },
      { label: "Frisco Public Library", value: "friscolibrary" },
      { label: "Fulton County Library System", value: "fulcolibrary" },
      { label: "Gail Borden Public Library District", value: "gailborden" },
      { label: "Grand Rapids Public Library", value: "grpl" },
      { label: "Greene County Public Library", value: "greenelibrary" },
      { label: "Greenwich Library", value: "greenwichlibrary" },
      { label: "Gwinnett County Public Library", value: "gwinnett" },
      { label: "Hennepin County Library", value: "hclib" },
      { label: "Herrick District Library", value: "herrickdl" },
      {
        label: "Hillsborough County Public Library Cooperative",
        value: "hcplc",
      },
      { label: "MORE Libraries", value: "more" },
      { label: "Indianapolis Public Library", value: "indypl" },
      { label: "Jefferson County Public Library", value: "jeffcolibrary" },
      { label: "Johnson County Public Library", value: "jocolibrary" },
      { label: "Kansas City Public Library", value: "kclibrary" },
      { label: "King County Library System", value: "kcls" },
      { label: "Las Vegas-Clark County Library District", value: "lvccld" },
      { label: "Lawrence Public Library", value: "lawrence" },
      { label: "Marin County Free Library", value: "marinlibrary" },
      { label: "MARINet Libraries", value: "marinet" },
      { label: "Mid-Continent Public Library", value: "mymcpl" },
      { label: "Multnomah County Library", value: "multcolib" },
      { label: "Ocean City Free Public Library", value: "oceancity" },
      { label: "Olathe Public Library", value: "olathe" },
      { label: "Omaha Public Library", value: "omaha" },
      { label: "PAC2", value: "pac2" },
      { label: "Palo Alto City Library", value: "paloalto" },
      { label: "Petoskey District Library", value: "petoskey" },
      { label: "Pima County Public Library", value: "pima" },
      { label: "Pleasanton Library", value: "pleasantonlibrary" },
      { label: "Portland Public Library", value: "portlandlibrary" },
      { label: "Princeton Public Library", value: "princetonlibrary" },
      { label: "Laurel County Public Library", value: "laurellibrary" },
      {
        label: "Public Library of Youngstown and Mahoning County",
        value: "plymc",
      },
      { label: "Ramsey County Library", value: "rclreads" },
      { label: "Saint Paul Public Library", value: "sppl" },
      { label: "San Antonio Public Library", value: "mysapl" },
      { label: "San Diego Public Library", value: "sandiego" },
      { label: "San Diego County Library", value: "sdcl" },
      { label: "San Francisco Public Library", value: "sfpl" },
      { label: "San José Public Library", value: "sjpl" },
      { label: "San Mateo County Libraries", value: "smcl" },
      { label: "Santa Clara City Library", value: "sclibrary" },
      { label: "Santa Clara County Library District", value: "sccl" },
      { label: "Santa Monica Public Library", value: "smpl" },
      { label: "Seattle Public Library", value: "seattle" },
      { label: "Skokie Public Library", value: "skokielibrary" },
      { label: "Sno-Isle Libraries", value: "sno-isle" },
      { label: "St. Joseph County Public Library", value: "sjcpl" },
      { label: "St. Louis Public Library", value: "slpl" },
      { label: "St. Tammany Parish Library", value: "stpl" },
      { label: "Sunnyvale Public Library", value: "sunnyvale" },
      { label: "Tacoma Public Library", value: "tacoma" },
      { label: "Topeka & Shawnee County Public Library", value: "tscpl" },
      { label: "Tulsa City-County Library", value: "tccl" },
      {
        label: "Washington County Cooperative Library Services",
        value: "wccls",
      },
      { label: "Whatcom County Library System", value: "wcls" },
      { label: "Yorba Linda Public Library", value: "ylpl" },
    ],
  },
];
